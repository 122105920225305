<template>
  <div class="content">
    <div v-if="ShowRouterTabs">
      <RouterTabs v-model="activeName" :tabArr="tabs" :queryData="query" :paramsData="params" :max="7" />
    </div>
    <router-view />
  </div>
</template>

<script>
import RouterTabs from "@/base/components/Default/RouterTabs.vue";
export default {
  data() {
    return {
      ShowRouterTabs: true, // 控制tab栏显示隐藏
      listArr: [
        "MyResume",
        "EditMember",
        "MemberMessage",
        // "ClassMessage",
        "MyCompanyList",
        "MyResources",
        "MyActivity",
        "MyLeaveMsgList",
        "MyOrder",
        "PositionRecode",
        "ReportRecode",
        "myCredential",
        "InvitationRevenue",
        "myIncomeRecords",
      ], // 带tab栏的页面路由name
      listArrS: [
        "EditMember",
        "MemberMessage",
        "ClassMessage",
        "MyCompanyList",
        "MyResources",
        "MyActivity",
        "MyLeaveMsgList",
        "MyOrder",
        "PositionRecode",
        "ReportRecode",
        "myCredential",
        "InvitationRevenue",
        "myIncomeRecords",
      ], // 带tab栏的页面路由name
      activeName: "EditMember", //当前选中tab
      query: {}, //路由的query参数
      params: {}, // 路由的params参数
      // tab默认配置
      default_tabs: [
        { label: "基本信息", name: "EditMember", params: true },
        {
          label: "会员信息",
          name: "MemberMessage",
          params: true,
        },
        // { label: "班级信息", name: "ClassMessage", params: true },
        { label: "我的企业", name: "MyCompanyList", params: true },
        { label: "我的资源", name: "MyResources", params: true },
        { label: "我的活动", name: "MyActivity", params: true },
        { label: "我的留言", name: "MyLeaveMsgList", params: true },
        { label: "我的订单", name: "MyOrder", params: true },
        { label: "职务记录", name: "PositionRecode", params: true },
        { label: "被举报记录", name: "ReportRecode", params: true },
        { label: "转账记录", name: "myCredential", params: true },
        { label: "邀请收益", name: "InvitationRevenue", params: true },
        { label: "等级积分记录", name: "myIncomeRecords", params: true },
      ],
      // school识别
      school_tabs: [
        { label: "基本信息", name: "EditMember", params: true },
        {
          label: "会员信息",
          name: "MemberMessage",
          params: true,
        },
        { label: "班级信息", name: "ClassMessage", params: true },
        { label: "我的企业", name: "MyCompanyList", params: true },
        { label: "我的资源", name: "MyResources", params: true },
        { label: "我的活动", name: "MyActivity", params: true },
        { label: "我的留言", name: "MyLeaveMsgList", params: true },
        { label: "我的订单", name: "MyOrder", params: true },
        { label: "职务记录", name: "PositionRecode", params: true },
        { label: "被举报记录", name: "ReportRecode", params: true },
        { label: "转账记录", name: "myCredential", params: true },
        { label: "邀请收益", name: "InvitationRevenue", params: true },
        { label: "等级积分记录", name: "myIncomeRecords", params: true },
      ],
      // tab配置
      tabs: [],
    };
  },
  created() {
    this.onTabs(this.$route.name)
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    listArrSchool() {
      return this.tenantVersion === 'school' ? this.listArrS:this.listArr
    },
    tabSchool() {
      if (this.tenantVersion === 'school') return this.school_tabs
      return this.default_tabs
    },
    isEmployFeature() {
      return this.$store.getters.tenant.features.includes('employ')
    },
  },
  watch: {
    routeName(val) {
      this.onTabs(val)
    },
  },
  components: { RouterTabs },
  methods: {
    onTabs(routeName) {
      this.ShowRouterTabs = !!this.listArrSchool.includes(routeName);

      if (this.ShowRouterTabs) {
        this.activeName = routeName

        const paramsData = this.$deCode(this.$route.params.data);

        if (paramsData?.type) {
          this.tabs = paramsData.type === "register" ? [] : this.tabSchool;
          if (paramsData.type !== "register") {
            const tabsData = this.tabs.find(
                (el) => el.name === "MemberMessage"
            );
            tabsData.label =
                paramsData.type === "register" ? "申请信息" : "会员信息";
          }
        }

        if (this.isEmployFeature) {
          if (this.tabs.find(t => t.name === 'MyResume')) {
            return
          }
          this.tabs.unshift({
            label: "我的简历", name: "MyResume", params: true
          })
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
}
</style>
